<template>
    <div class="regbox">
        <el-row class="regtitle">泰易达城市配注册页面</el-row>
        <el-row class="regconcent">
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <a href="javascript:;" class="Service-item">
                        <div class="item-image">
                            <img src="../assets/icon-user.png" alt="">
                        </div>
                        <h3 class="item-title">注册成为城市配用户</h3>
                        <div class="item-text"></div>
                        <el-button class="item-link " size="mini" type="primary" @click="cityUserReg">注册成为城市配用户</el-button>
                    </a>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple-light">
                    <a href="javascript:;" class="Service-item">
                        <div class="item-image">
                            <img src="../assets/icon-company.png" alt="">
                        </div>
                        <h3 class="item-title">注册成为城市配承运商</h3>
                        <div class="item-text"></div>
                        <el-button class="item-link " size="mini" type="primary" @click="cityCarrierReg">注册成为城市配承运商</el-button>
                    </a>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <a href="javascript:;" class="Service-item">
                        <div class="item-image">
                            <img src="../assets/icon-driver.png" alt="">
                        </div>
                        <h3 class="item-title">注册成为司机</h3>
                        <div class="item-text"></div>
                        <el-button class="item-link " size="mini" type="primary" @click="cityDriverReg">注册成为城市配司机</el-button>
                    </a>
                </div>
            </el-col>
        </el-row>
        <el-row class="regfotter">
            <el-col style="text-align: center">浙公网安备33010802003130号 © 浙ICP备15040565号 © 版权所有 浙江泰易达物流科技有限公司</el-col>
        </el-row>
    </div>
</template>

<style scoped>
    .regbox{
        width: 100%;
        height: 100%;
    }
    .regbox .regtitle {
        width: 100%;
        height: 10%;
        color: #409eff;
        font-size: 30px;
        text-align: center;
        line-height: 60px;
    }
    .regbox .regconcent {
        width: 100%;
        height: 80%;
    }
    .regbox .regfotter {
        width: 100%;
        height: 10%;
    }
    .regbox .el-col {
        height: 100%;
        position: relative;
    }
    .regbox .grid-content{
        width: 340px;
        height: 300px;
        position: absolute;
        left: 0;
        right: 0;
        top:0;
        bottom: 0;
        margin: auto;
    }
    a {
        text-decoration: none;
        color: #000;
    }

    img {
        border: 0;
    }
    button {
        outline: 0;
    }
    button,input,optgroup,select,textarea {
        margin: 0;
        font: inherit;
        color: inherit;
        outline: none;
    }
    /* 必要布局样式css */
    .Service-box {
        background-color: #fff;
        -ms-box-sizing: border-box;
        -o-box-sizing: border-box;
        box-sizing: border-box;
    }


    .Service-item {
        display: block;
        width: 340px;
        padding: 30px 16px;
        text-align: center;
        float: left;
        height: 300px;
        margin: 0 0 10px;
        border: 1px solid #E7E7E7;
        box-sizing: border-box;
        border-radius: 10px;
        overflow: hidden;
    }

    .grid-content.Service-item {
        -webkit-transition: all 160ms;
        transition: all 160ms;
    }

    .item-image {
        display: inline-block;
        height: 100px;
        width: 100px;
        line-height: 100px;
        text-align: center;
        border: 3px solid transparent;
        margin: 0;
        border-radius: 100%;
        -ms-box-sizing: border-box;
        -o-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transition: all 160ms;
        transition: all 160ms;
    }

    .item-image img {
        width: 100%;
        height: 100%;
        display: block;
        border: none;
    }

    .item-title {
        line-height: 25px;
        font-size: 18px;
        color: #3D3D3D;
        padding: 22px 0 7px;
        font-family: 'PingFangSC-Regular', "Microsoft YaHei", Tahoma, sans-serif;
        font-weight: normal;
    }

    .item-text {
        color: #9A9A9A;
        font-size: 12px;
        line-height: 17px;
        height: 100px;
        overflow: hidden;
        font-family: 'PingFangSC-Regular', "Microsoft YaHei", Tahoma, sans-serif;
    }

    .item-link {
        width: 300px;
        height: 40px;
        font-family: 'PingFangSC-SC', "Microsoft YaHei", Tahoma, sans-serif;
        color: #FFFFFF;
        display: none;
        padding: 6px 20px;
        background-color: #05A6F3;
        margin: 17px auto;
        font-size: 14px;
        line-height: 20px;
        border-radius: 5px;
        -webkit-transition: all 160ms;
        transition: all 160ms;
        font-weight: normal;
    }

    .Service-item:hover {
        border-color: #DEDDDD;
        padding-top: 26px;
        position: relative;
        z-index: 99;
        text-decoration: none;
        box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
        webkit-transform: translate3d(0, -3px, 0);
        transform: translate3d(0, -3px, 0);
    }

    .Service-item:hover .item-image {
        height: 60px;
        width: 60px;
        line-height: 60px;
        margin: 0 auto;
    }

    .Service-item:hover .item-link {
        display: block;
    }

    .Service-item:hover .item-title {
        padding: 10px 0 7px;
    }

    .Service-item + .Service-item {
        margin-left: 50px;
        position: relative;
    }

    .item-tag {
        width: 67px;
        height: 67px;
        color: #FFFFFF;
        position: absolute;
        transform: rotateZ(-315deg);
        top: 9px;
        right: 9px;
        font-size: 12px;
        line-height: 17px;
    }

    .item-background {
        width: 67px;
        height: 67px;
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .item-background img {
        width: 100%;
        height: 100%;
    }
</style>

<script>
    export default{
        data () {
            return {msg: '这个是Home模板页'}
        },
        methods: {
            login(){
                alert("aaa")
            },
            //用户注册
            cityUserReg(){
                let routeUrl = this.$router.resolve({
                    path: "/cityUserReg"
                });
                window.open(routeUrl.href, '_blank');
            },
            //承运商注册
            cityCarrierReg(){
                let routeUrl = this.$router.resolve({
                    path: "/cityCarrierReg"
                });
                window.open(routeUrl.href, '_blank');
            },
            //司机
            cityDriverReg(){
                let routeUrl = this.$router.resolve({
                    path: "/cityDriverReg"
                });
                window.open(routeUrl.href, '_blank');
            }
        }
    }
</script>
